import setupDropdowns from "./dropdown";

function profileAddMoreLang() {
    const addButtonLang = document.getElementById('add-language');
    const languageContainer = document.querySelector('.edit-languages');

    if (!addButtonLang || !languageContainer) {
        console.error('Required elements (button or container) not found.');
        return;
    }

    if (!addButtonLang.dataset.initialized) {
        addButtonLang.dataset.initialized = true;

        addButtonLang.addEventListener('click', function(event) {
            event.preventDefault();

            const currentCount = languageContainer.querySelectorAll('.language-section').length + 1;

            // HTML for the new language section
            const newLanguageHtml = `
               <div class="row gy-2 language-section my-border-top">
                    <div class="col-md-6 register-col">
                        <label for="validationTooltip-lang2-${currentCount}" class="form-label register-label font-psb">Иностранные языки</label>
                        <div class="select-menu">
                            <select class="select-btn login-input form-select" id="validationTooltip-lang2-${currentCount}" name="foreign_language[]">
                                <option selected disabled>Выберите</option>
                                <option value="Английский">Английский</option>
                                <option value="Французский">Французский</option>
                                <option value="Немецкий">Немецкий</option>
                                <option value="Испанский">Испанский</option>
                                <option value="Китайский">Китайский</option>
                            </select>
                            <div class="invalid-feedback">
                                Please select a valid foreign language.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 register-col">
                        <label for="validationTooltip-lang3-${currentCount}" class="form-label register-label font-psb">Уровень владения</label>
                        <div class="select-menu">
                            <select class="select-btn login-input form-select" id="validationTooltip-lang3-${currentCount}" name="proficiency_level[]">
                                <option selected disabled>Выберите</option>
                                <option value="Начальный">Начальный</option>
                                <option value="Средний">Средний</option>
                                <option value="Продвинутый">Продвинутый</option>
                                <option value="Свободный">Свободный</option>
                            </select>
                            <div class="invalid-feedback">
                                Please select a valid proficiency level.
                            </div>
                        </div>
                    </div>
                    
                    <div class="profile-form-btn-wrapper">
                        <button type="button" class="profile-form-btn-delete"><span class="profile-form-span-delete"></span>Удалить язык</button>
                    </div>
               </div>`;

            const div = document.createElement('div');
            div.innerHTML = newLanguageHtml;
            languageContainer.appendChild(div);

            // Event listener for the delete button
            div.querySelector('.profile-form-btn-delete').addEventListener('click', function() {
                div.remove(); // Remove this language section
            });

            // Reinitialize dropdowns or other elements
            setupDropdowns();

            console.log('New language section added.');
        });
    }
}

export default profileAddMoreLang;
