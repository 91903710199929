import setupDropdowns from "./dropdown";

function profileAddMore() {
    const addButton = document.getElementById('add-education'); // The button to add education fields
    const educationContainer = document.querySelector('.edit-education'); // Container where education sections are placed

    // Check if the button or container is missing in the DOM
    if (!addButton || !educationContainer) {
        console.error('Required elements (button or container) not found in the DOM.');
        return; // Exit if elements are not found
    }

    // Avoid multiple bindings of the event listener
    if (!addButton.dataset.initialized) {
        addButton.dataset.initialized = true; // Mark the button as initialized

        // Attach an event listener to handle the button click
        addButton.addEventListener('click', function(event) {
            event.preventDefault(); // Prevent default form submission behavior

            // Count the current number of education sections to assign unique IDs
            const currentCount = educationContainer.querySelectorAll('.education-section').length + 1;

            // HTML for the new education section with a delete button
            const newEducationHtml = `
                <div class="education-section my-border-top">
                    <div class="row gy-2 gx-5">
                        <div class="col-md-6 register-col">
                            <label for="validationTooltip-edu1-${currentCount}" class="form-label register-label font-psb">Вид образования</label>
                            <div class="select-menu">
                                <select class="select-btn login-input form-select" id="validationTooltip-edu1-${currentCount}" name="education_type[]">
                                    <option selected disabled>Выберите</option>
                                    <option value="Среднее">Среднее</option>
                                    <option value="Среднее специальное">Среднее специальное</option>
                                    <option value="Неоконченное высшее">Неоконченное высшее (Бакалавр)</option>
                                    <option value="Высшее">Высшее (Магистр)</option>
                                    <option value="Кандидат Наук">Кандидат Наук</option>
                                    <option value="Доктор наук">Доктор наук</option>
                                </select>
                                <div class="invalid-feedback">Please select a valid state.</div>
                            </div>
                        </div>
                        
                        <div class="col-md-6 register-col">
                            <label for="validationTooltip-edu2-${currentCount}" class="form-label register-label font-psb">Название учебного заведения</label>
                            <div class="login-input university-wrapper">
                                <input type="text" class="form-control" placeholder="Выберите или введите" required id="validationTooltip-edu2-${currentCount}" name="institution_name[]">
                                <div class="invalid-feedback">Please choose a university.</div>
                            </div>
                        </div>

                        <div class="col-md-6 register-col">
                            <label for="validationTooltip-edu3-${currentCount}" class="form-label register-label font-psb">Факультет</label>
                            <div class="login-input">
                                <input type="text" class="form-control" placeholder="Введите..." required id="validationTooltip-edu3-${currentCount}" name="faculty_name[]">
                                <div class="invalid-feedback">Please choose a faculty.</div>
                            </div>
                        </div>

                        <div class="col-md-6 register-col">
                            <label for="validationTooltip-edu4-${currentCount}" class="form-label register-label font-psb">Год поступления</label>
                            <div class="login-input login-input-date-picker">
                                <input type="date" id="validationTooltip-edu4-${currentCount}" name="start_year[]" placeholder="Выберите дату" min="1980-01-01" max="2024-01-01" required>
                                <div class="invalid-feedback">Please choose a valid start year.</div>
                            </div>
                        </div>

                        <div class="col-md-6 register-col">
                            <label for="validationTooltip-edu5-${currentCount}" class="form-label register-label font-psb">Год окончания</label>
                            <div class="login-input login-input-date-picker">
                                <input type="date" id="validationTooltip-edu5-${currentCount}" name="end_year[]" placeholder="Выберите дату" min="1980-01-01" max="2024-01-01" required>
                                <div class="invalid-feedback">Please choose a valid end year.</div>
                            </div>
                        </div>

                        <!-- Delete button for each dynamically added education section -->
                        <div class="profile-form-btn-wrapper">
                            <button type="button" class="profile-form-btn-delete"><span class="profile-form-span-delete"></span>Удалить место обучения</button>
                        </div>
                    </div>
                </div>`;

            // Create a new div element and set its inner HTML
            const div = document.createElement('div');
            div.innerHTML = newEducationHtml;
            educationContainer.appendChild(div); // Append the new div to the container

            // Add event listener to the delete button in this newly added section
            div.querySelector('.profile-form-btn-delete').addEventListener('click', function() {
                div.remove(); // Remove this education section
            });

            // Reinitialize dropdowns in the newly added education section
            setupDropdowns(); // Ensure new dropdowns are initialized

            console.log('New education section added.'); // Log the action to the console
        });
    }
}

export default profileAddMore;
