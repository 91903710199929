import setupDropdowns from "./dropdown";

function profileAddJob() {
    const addButton = document.getElementById('add-work-experience');
    const jobContainer = document.querySelector('.edit-work-experience .work-experience');

    if (!addButton || !jobContainer) {
        console.error('Required elements (button or container) not found.');
        return;
    }

    if (!addButton.dataset.initialized) {
        addButton.dataset.initialized = true;

        addButton.addEventListener('click', function(event) {
            event.preventDefault();

            const currentCount = jobContainer.querySelectorAll('.work-experience-section').length + 1;

            const newJobHtml = `
                <div class="row gy-2 gx-5 work-experience-section my-border-top">
                    <div class="col-md-6 register-col">
                        <label for="validationTooltip-company-${currentCount}" class="form-label register-label font-psb">Название компаний</label>
                        <div class="login-input">
                            <input type="text" class="form-control" name="company_name[]" placeholder="Введите..." required id="validationTooltip-company-${currentCount}">
                            <div class="invalid-feedback">Please choose a company name.</div>
                        </div>
                    </div>

                    <div class="col-md-6 register-col">
                        <label for="validationTooltip-position-${currentCount}" class="form-label register-label font-psb">Название должности</label>
                        <div class="login-input">
                            <input type="text" class="form-control" name="position_name[]" placeholder="Введите..." required id="validationTooltip-position-${currentCount}">
                            <div class="invalid-feedback">Please choose a position name.</div>
                        </div>
                    </div>

                    <div class="col-md-12 register-col">
                        <label for="validationTooltip-duties-${currentCount}" class="form-label register-label font-psb">Расскажите о ваших обязанностях и достижениях</label>
                        <textarea id="validationTooltip-duties-${currentCount}" name="duties_achievements[]" cols="30" rows="10" class="border-wrapper profile-text-area input-inner-text" placeholder="Введите..." required></textarea>
                        <div class="invalid-feedback">Please describe your duties and achievements.</div>
                    </div>

                    <div class="col-md-4 register-col">
                        <label for="validationTooltip-start-year-${currentCount}" class="form-label register-label font-psb">Год начала работы</label>
                        <div class="login-input login-input-date-picker">
                            <input type="date" id="validationTooltip-start-year-${currentCount}" name="start_date[]" placeholder="Выберите дату" required>
                            <div class="invalid-feedback">Please choose a start date.</div>
                        </div>
                    </div>

                    <div class="col-md-4 register-col">
                        <label for="validationTooltip-end-year-${currentCount}" class="form-label register-label font-psb">Год окончания</label>
                        <div class="login-input login-input-date-picker">
                            <input type="date" id="validationTooltip-end-year-${currentCount}" name="end_date[]" placeholder="Выберите дату" required>
                            <div class="invalid-feedback">Please choose an end date.</div>
                        </div>
                    </div>

                    <div class="col-md-4 d-flex align-items-end">
                        <div class="form-check d-flex align-items-center">
                            <input class="form-check-input" name="current_job[]" type="checkbox" id="current-job-checkbox-${currentCount}">
                            <label class="form-check-label font-pr" for="current-job-checkbox-${currentCount}">По настоящее время</label>
                        </div>
                    </div>

                    <!-- Delete button for the work experience section -->
                    <div class="profile-form-btn-wrapper">
                        <button type="button" class="profile-form-btn-delete"><span class="profile-form-span-delete"></span>Удалить место работы</button>
                    </div>
                </div>`;

            // Create a new div element and insert the new job HTML
            const div = document.createElement('div');
            div.innerHTML = newJobHtml;
            const jobSection = div.firstElementChild; // Get the first child (the section itself)
            jobContainer.appendChild(jobSection); // Append the new section to the container

            // Add event listener to the delete button in this newly added section
            jobSection.querySelector('.profile-form-btn-delete').addEventListener('click', function() {
                jobSection.remove(); // Remove this job section
            });

            // Reinitialize dropdowns and any other interactive elements
            setupDropdowns(); // Ensure new dropdowns are initialized

            console.log('New job section added.'); // Log the action to the console
        });
    }
}

export default profileAddJob;
