function registryForm() {
    const userType = document.querySelector('.register-page')?.classList.contains('student') ? 'student' : 'company';
    const sections = document.querySelectorAll('.register-student-section');
    // const progressBar = document.querySelector('.progress-bar'); // Commented out progress bar logic since it's not needed
    const totalSteps = sections.length;

    let currentStep = 0; // Start from the first page by default

    setupFormNavigation();
    updateFormSteps();

    function setupFormNavigation() {
        sections.forEach((section, index) => {
            const prevBtn = section.querySelector('.prevBtn');
            const nextBtn = section.querySelector('.nextBtn');

            prevBtn?.addEventListener('click', () => handlePrevNextButtons(-1));
            nextBtn?.addEventListener('click', () => handlePrevNextButtons(1));
        });

        // document.querySelector('form').addEventListener('submit', handleFormSubmit);
        // Submit handling can be added here if needed later
    }

    function handlePrevNextButtons(stepChange) {
        const currentSection = sections[currentStep];

        // if (stepChange === 1 && !validateForm(currentSection)) {
        //     return; // Stop if the current section is not valid (no frontend validation as per requirement)
        // }

        let newStep = currentStep + stepChange;
        if ((stepChange === -1 && newStep >= 0) || (stepChange === 1 && newStep < totalSteps)) {
            currentStep = newStep;
            updateFormSteps();
        }
    }

    // function validateForm(section) {
    //     const form = section.querySelector('.needs-validation');
    //     if (form.checkValidity()) {
    //         form.classList.remove('was-validated');
    //         return true;
    //     } else {
    //         form.classList.add('was-validated');
    //         return false;
    //     }
    // }

    function updateFormSteps() {
        sections.forEach((section, index) => {
            section.style.display = index === currentStep ? 'block' : 'none';
        });

        // const progressPercentage = (currentStep / (totalSteps - 1)) * 100;
        // if (progressBar) {
        //     progressBar.style.width = `${progressPercentage}%`;
        // }
    }

    // function handleFormSubmit(event) {
    //     event.preventDefault();
    //     // Add logic for when form is submitted
    //     alert('Form submitted successfully!');
    // }

    // Initialize form steps
    updateFormSteps();
}

export default registryForm;
